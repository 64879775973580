import React, { Component } from "react";
import { Container, Grid2 } from "@mui/material";


class Footer extends Component {
  construnctor(props) { }

  render() {
    const preventDefault = event => event.preventDefault();
    return (
      <footer style={{ position: "relative", bottom: 0, left: 0, right: 0 }}>
        <Container>
          <Grid2 container justify="center" className="footer-grid">
            <Grid2 md={4} xs={12} item className="footer-item">
              <a href="/" className="footer-logo">
                <img className="App-logo" src="/imgs/logo-white.png" alt="WeAreFabbrik" />
              </a>

              <span className="marginTop25">info@wearefabbrik.com</span>
              <span>Sepapaja tn 6, Tallinn</span>
              <span>Estonia</span>
              <a className="footer-logo" href="https://ee.linkedin.com/company/wearefabbrik?trk=public_profile_topcard-current-company" target="_blank" rel="noopener noreferrer">LI</a>
            </Grid2>
            <Grid2 md={4} xs={12} item></Grid2>
            <Grid2 md={4} xs={12} item></Grid2>
          </Grid2>
          <div className="trademark">
            © 2025 by WeAreFabbrik OÜ. All rights reserved
          </div>
          {/* <div className="privacy-policy">
            <Link href="/policy" color="inherit">
              {"Privacy Policy"}
            </Link>
          </div> */}
        </Container>
      </footer>
    );
  }
}
export default Footer;
