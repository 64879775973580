import React, { useEffect } from "react";
import { loadCSS } from "fg-loadcss";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Values from "./components/Values";
import UnderConstruction from "./pages/UnderConstructionPage";
import Clients from "./components/Clients";
function App() {
  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  const isUnderDevelopment = process.env.ISDEV || false;
  const sections = (
    <div className="App">
      <Header />
      <Services />
      <Values />
      <Clients/>
      <Footer />
    </div>
  );

  return isUnderDevelopment ? <UnderConstruction /> : sections;
}

export default App;
