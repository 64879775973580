import React, { useState, useRef, useEffect } from "react"
import { Container, Link } from "@mui/material"
import { NavLink } from "react-router";

const menuItems = [
    { link: "/services", title: "Services", class: "menu-item", isPage: true },
    { link: "/projects", title: "Projects", class: "menu-item", isPage: true },
    { link: "/contact", title: "Contact Us", class: "menu-item", isPage: true }
];

let navlInks = menuItems.map(item => (
    item?.isPage ?
        <NavLink
            key={item.link}
            to={item.link}
            className={item.class}
        >
            {item.title}
        </NavLink> :
        <Link
            key={item.link}
            href={'/#' + item.link}
            className={item.class}
        >
            {item.title}
        </Link>

));

const TopNav = () => {
    const navRef = useRef(null);
    const [navClass, setNavClass] = useState("navbar");

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setNavClass(navClass + " active-nav");
            } else {
                setNavClass("navbar");
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return <div className={navClass} ref={navRef}>
        <Container>
            <nav className="header_topnav_right">{navlInks}</nav>
            <a href="/">
                <nav className="header_topnav_left">
                    <img
                        alt="we are fabbrik logo"
                        className="App-logo"
                        src="/imgs/logo-white.png"
                    />
                    <img
                        alt="we are fabbrik logo"
                        className="app-logo-scroll"
                        src="/imgs/logo-green.png"
                    />
                </nav>
            </a>
        </Container>
    </div>
}

export default TopNav