import React, { useState } from "react";
import {
  Link,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  FormControlLabel,
  Checkbox,
  Container
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import emailjs from "emailjs-com";
import HashLoader from "react-spinners/HashLoader";
import { useMediaQuery } from "react-responsive";
import MobileNav from "../components/MobileNav";
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";

function Contact() {
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailClass, setEmailClass] = useState("contact-label");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tel, setTel] = useState("");
  const [company, setCompany] = useState("");
  const [privacy, setPrivacy] = useState(true);

  const sendMail = () => {
    setLoading(true);
    const data = {
      email,
      name,
      description,
      tel,
      company
    };

    const templateParams = {
      reply_to: email,
      from_name: name,
      message: description,
      telephone: tel,
      company
    };

    const service_id = "service_vcl9f3q";
    const template_id = "template_3rwd2d9";
    emailjs
      .send(
        service_id,
        template_id,
        templateParams,
        "1vmSLpYUsuzvbnVnY"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text);
          setLoading(false);
        },
        err => {
          console.log("FAILED...", err);
          setLoading(false);
        }
      );
    setSubmited(true);
  };

  const validateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  const privacyText = (
    <span className="privacy-policy-check">
      I agree to the{" "}
      <Link style={{ display: "contents" }} target="_blank" href="/policy.html">
        Privacy Policy
      </Link>
    </span>
  );
  const form = (
    <div>
      <FormControl>
        <InputLabel className="contact-label" htmlFor="name">
          Full Name*
        </InputLabel>
        <Input
          required
          className="contact-input"
          id="name"
          type="text"
          aria-describedby="Enter your Full Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <InputLabel className={emailClass} htmlFor="email">
          Email address*
        </InputLabel>
        <Input
          required
          className="contact-input"
          id="email"
          type="email"
          aria-required="true"
          aria-describedby="Enter your email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
            if (!validateEmail(e.target.value)) {
              setEmailClass(emailClass + " form-error");
            } else {
              setEmailClass("contact-label");
            }
          }}
        />
        <FormHelperText id="email-helper" className="contact-helper-text">
          We'll never share your email.
        </FormHelperText>
      </FormControl>
      <FormControl>
        <InputLabel className="contact-label" htmlFor="company">
          Company
        </InputLabel>
        <Input
          className="contact-input"
          id="company"
          aria-describedby="Enter your company"
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <InputLabel className="contact-label" htmlFor="phone">
          Phone
        </InputLabel>
        <Input
          className="contact-input"
          id="phone"
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          aria-describedby="Enter your phone"
          onChange={e => setTel(e.target.value)}
          value={tel}
        />
      </FormControl>
      <FormControl fullWidth className="form-control">
        <InputLabel className="contact-label" htmlFor="description">
          What can we do for you?*
        </InputLabel>
        <Input
          required
          fullWidth
          multiline={true}
          rows={3}
          // rowsMax={3}
          className="contact-input"
          id="description"
          aria-describedby="Enter your description"
          onChange={e => setDescription(e.target.value)}
          value={description}
        />
      </FormControl>
      <FormControl fullWidth className="form-control">
        <FormControlLabel
          className="contact-label"
          control={
            <Checkbox
              checked={privacy}
              onChange={() => setPrivacy(!privacy)}
              size="medium"
            />
          }
          label={privacyText}
        />
      </FormControl>
      <FormControl fullWidth className="form-control">
        <Button
          className="form-button"
          onClick={sendMail}
          disabled={
            !(email.length && name.length && description.length) ||
            !validateEmail(email) ||
            !privacy
          }
        >
          Send
        </Button>
        <FormHelperText id="button-helper" className="contact-helper-text">
          Fields with * are mandatory
        </FormHelperText>
      </FormControl>
    </div>
  );

  const submitedMessage = (
    <div>
      <h2>Thank you for contacting us!</h2>
      <h3>We will get back to you shortly!</h3>
    </div>
  );

  const loader = <HashLoader size={50} color={"#479761"} loading={loading} />;

  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  return (
    <div id="contact" className="contact-container">
      {isMobileDevice ? <MobileNav /> : <TopNav />}
      <div className="case-studies-img">
        <div className="overlay-mask" />
        <Container style={{ maxWidth: '1200px' }}>
          <Typography className="page-header" variant="h1">Contact</Typography>
        </Container>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent={"center"}>
          <Grid size={{ xs: 12, sm: 10, md: 7 }}>
            <Typography variant="h2" component="h2" className="section-title">
              Contact Us
            </Typography>
            {!submited && form}
            {loader}
            {submited && !loading && submitedMessage}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
}

export default Contact;
