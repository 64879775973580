import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import PopupWithIframe from './IframePopup';
import { Link } from '@mui/material';

const Project = ({ id, img, desc, url, title, more }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const showPopup = () => setIsPopupVisible(true);
    return (
        <Link href={url} target='_blank' underline='none'>
            <Card sx={{ maxWidth: 500 }} className='project-card' >
                <CardActionArea>
                    <div className='project-img-container' onClick={showPopup}>
                        <img className='project-img' src={img} alt={desc} />
                    </div>
                    {/* <CardMedia
                        className='project-img'
                        component="img"
                        // height="100"
                        image={logo}
                        alt={desc}
                    /> */}
                    <CardContent>
                        <Typography variant="h4" sx={{ color: 'var(--theme-crocke)' }}>{title}</Typography>
                        <Typography variant="body1" className='project-desc'>
                            {desc}
                        </Typography>
                        &nbsp;
                        {more && (
                            <Typography variant="body1" component="ul" className='project-desc'>
                                {more.map((example, index) => (
                                    <li key={index}>{example}</li>
                                ))}
                            </Typography>
                        )}
                        {/* <PopupWithIframe url={img} isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} /> */}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    );
}
export default Project