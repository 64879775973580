import React, { useState } from "react";
import {
  Link,
  Button,
  Grid,
  Paper,
  Typography,
  Icon,
  Tabs,
  Tab,
  Container
} from "@mui/material";
import TabPanel from "./TabPanel";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

function Values() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const valueList = [
    {
      id: 0,
      icon: "fa fa-lightbulb",
      title: "Think, analyze and plan ahead.",
      text:
        "Your needs are carefully recorded and analyzed in order to create value for your business."
    },
    {
      id: 1,
      icon: "fa fa-shield-alt",
      title: "Reliability is our top priority",
      text:
        "We provide reliable software solutions and services at competitive prices and deliver them on time."
    },
    {
      id: 2,
      icon: "fas fa-globe",
      title: "Driving Innovation",
      text:
        "Each application we build is uniquely designed and built using cutting-edge technologies."
    }
  ];

  const tabs = (
    <Tabs
      className="value-tabs"
      value={value}
      onChange={handleChange}
      aria-label="simple tabs"
      centered
    >
      {valueList.map(item => (
        <Tab
          icon={<Icon className={item.icon}  style={{ fontSize: 30}}/>}
          {...a11yProps(item.id)}
          key={item.id}
        />
      ))}
    </Tabs>
  );

  const tabPanels = valueList.map(item => (
    <TabPanel value={value} index={item.id} key={item.id}>
      <Typography variant="h3" component="h3" className="value-title">
        {item.title}
      </Typography>
      <Typography variant="h4" component="h4" className="value-text">
        {item.text}
      </Typography>
    </TabPanel>
  ));

  return (
    <section id="values">
      <div className="value-img"></div>
      <div className="overlay-mask"></div>
      <Container className="values-container" >
        <Typography variant="h2" component="h2" className="section-title">
          Our Core Values
        </Typography>
        {tabs}
        {tabPanels}
      </Container>
    </section>
  );
}

export default Values;
