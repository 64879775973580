import React, { useEffect, useRef } from "react";
import { Container, Typography, Button } from "@mui/material";
import { Parallax } from "react-scroll-parallax";
import { useMediaQuery } from 'react-responsive';
import TopNav from "./TopNav";
import MobileNav from "./MobileNav";

function AppHeader() {
  const vidRef = useRef(null)

  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  const headerBack = isMobileDevice ? (<img id="hero" alt="header" src="/imgs/hero-mobi.jpg" />) :
    <video playsInline autoPlay muted loop width="100%" id="header-video" ref={vidRef}>
      <source src="../videos/video.mp4" type="video/mp4" />
    </video>

  const playVideo = () => {
    vidRef.current.play()
  }

  useEffect(() => {
    !isMobileDevice && playVideo()
  }, [])

  const ParallaxImage = () => (
    <Parallax
      className="custom-class"
      offsetYMin={-100}
      offsetYMax={100}
      tagOuter="figure"
    >
      <img id="hero" src="/imgs/hero.jpg" />
    </Parallax>
  );
  return (
    <header>
      {isMobileDevice ? <MobileNav /> : <TopNav />}
      <section>
        {/* <img id="hero" src="/imgs/hero.jpg" /> */}
        {/* {ParallaxImage} */}
        <div className="video-container">{headerBack}</div>
        <Container>
          <div className="headline-container">
            <Typography component="h1" variant="h1" className="headline">
              We Are A Custom Software Development Company
            </Typography>
            <Typography component="h3" variant="h3" className="headline">
              Turning Your Great Ideas into Successful Web Solutions
            </Typography>
            <Typography component="h4" variant="h4" className="subtitle">
              We have the experience and the expertise to build your custom web
              & mobile solutions. IT consulting, software development and
              testing is what we do.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="header-button"
              href="https://calendly.com/tsolakidis_k/30min"
              target="_blank"
            >
              Book A Call
            </Button>
          </div>
        </Container>
      </section>
    </header>
  );
}

export default AppHeader;
