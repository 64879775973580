import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Box, Divider, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Link } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';

const MobileNav = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: 'Home', icon: <HomeIcon />, link: '/' },
        { text: 'Services', icon: <InfoIcon />, link: '/services' },
        { text: 'Projects', icon: <WorkIcon />, link: '/projects' },
        { text: 'Contact', icon: <ContactMailIcon />, link: '/contact' },
    ];

    const list = () => (
        <Box
            sx={{ width: '80vw', height: "30%" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Stack direction={'row'} style={{ borderBottom: '1px solid black' }} justifyContent="space-between">
                <a href="/">
                    <img
                        alt="we are fabbrik logo"
                        className="app-logo-mobile"
                        src="/imgs/logo.png"
                    />
                </a >
                <IconButton
                    edge="end"
                    aria-label="menu"
                    onClick={toggleDrawer(false)}
                    className='menu-close'
                >
                    <CloseIcon />
                </IconButton>
                {/* <Divider /> */}
            </Stack>
            <List >
                {menuItems.map((item, index) => (
                    <ListItem button key={index} component={Link} to={item.link}>
                        <ListItemIcon className='mobile-menu-li'>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} className='mobile-menu-li' />
                    </ListItem>
                ))}
            </List>
        </Box >
    );

    return (
        <React.Fragment>
            <AppBar position="fixed" color="transparent">
                <Toolbar>
                    <IconButton
                        edge="start"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon className='burger-icon' />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                style={{ width: '100vw', height: "30%" }}
            >
                {list()}
            </Drawer>
        </React.Fragment>
    );
};

export default MobileNav;