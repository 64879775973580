import React from "react";
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";
import { Container, Typography, Grid2 as Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { useMediaQuery } from "react-responsive";
import MobileNav from "../components/MobileNav";

const services = [
  {
    title: 'UI/UX Design',
    description: 'We create user-centric designs that are both aesthetically pleasing and highly functional, ensuring an engaging and intuitive user experience.',
    examples: [
      'Webside Design',
      'Mobile apps',
      'Web Apps'
    ],
    image: '/imgs/services/web-design.jpg',
  },
  {
    title: 'Custom Software Development',
    description: 'Developing tailored software solutions to meet your unique business needs.',
    examples: [
      'Customer Relationship Management (CRM) Platforms',
      'Inventory Management Solutions',
      'Employee Scheduling Applications',
      'Data Analytics Dashboards',
      'Automated Invoicing Systems',
      'Custom Microsoft Add-ins'
    ],
    image: '/imgs/services/custom-software-development.jpg',
  },
  {
    title: 'Website Development',
    examples: [
      'Blogs',
      'Corporate Websites With Payment Integration',
      'e-commerce platforms'
    ],
    description: 'Our experts craft responsive, mobile friendly and dynamic websites designed to captivate visitors and foster meaningful engagement.',
    image: '/imgs/services/website-development.jpg',
  },
  {
    title: 'Legacy Software Modernization',
    description: 'We upgrade outdated systems to modern standards, improving performance, scalability, and aligning them with current business needs.',
    examples: [
      'Outdated Application Migration',
      'Legacy Code Refactoring'
    ],
    image: '/imgs/services/legacy-software-modernization.jpg',
  },
  {
    title: 'Branding',
    description: 'Building strong brand identities that resonate with your target audience.',
    examples: [
      'Visual Identity',
      'Logo Design',
      'Packaging'
    ],
    image: '/imgs/services/branding.jpg',
  },
];

const Services = () => {
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  return (
    <div>
      {isMobileDevice ? <MobileNav /> : <TopNav />}
      <div className="case-studies-img">
        <div className="overlay-mask" />
        <Container style={{ maxWidth: '1200px' }}>
          <Typography className="page-header" variant="h1">Our Services</Typography>
        </Container>
      </div>
      <Container sx={{ margin: "60px auto", textAlign: 'center' }}>
        <Grid container spacing={2} sx={{ textAlign: 'center', justifyContent: "center" }}>
          {services.map((service, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 350, textAlign: 'left' }}>
                <CardMedia
                  component="img"
                  height="240"
                  image={service.image}
                  alt={service.title}
                />
                <CardContent>
                  <Typography variant="h4" component="div" sx={{ color: 'var(--theme-crocke)' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.5rem' }}>
                    {service.description}
                    &nbsp;
                    {service.examples && (
                      <Typography variant="body1" color="text.secondary" component="ul" sx={{ fontSize: '1.5rem' }}>

                        {service.examples.map((example, index) => (
                          <li key={index}>{example}</li>
                        ))}
                      </Typography>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <div style={{ paddingTop: 20}}>
          <Button
            variant="contained"
            color="primary"
            className="service-button"
            href="https://calendly.com/tsolakidis_k/30min"
            target="_blank"
          >
            Book A Call
          </Button>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Services;