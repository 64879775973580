import React, { Component } from "react";
import { Container, Stack, Typography, Button } from "@mui/material";
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";
import Project from "../components/Project";
import { useMediaQuery } from "react-responsive";
import MobileNav from "../components/MobileNav";

const projects = [
  {
    id: 1,
    title: "Resolute S.A.",
    img: "/imgs/clients/www_resolute_gr-website.jpeg",
    url: "https://www.resolute.gr/",
    desc: `Our team has been working tirelessly to create a dynamic, user-friendly, and visually stunning online presence that truly represents Resolute Maritime Services's brand and vision. 
              Here are some highlights of the new site:`,
    more: [
      '🌐 Modern Design: Sleek, responsive, and optimized for all devices to ensure an engaging user experience.',
      '⚙️ Enhanced Functionality: Seamless navigation and innovative features to meet the needs of both existing and potential customers.',
      '🔍 SEO Optimized: Strategically crafted content and structure to improve search engine rankings and drive organic traffic.'
    ]
  },
  {
    id: 2,
    title: "L-Crew Sports Endurance Team",
    img: "/imgs/clients/screenshot-www_l-crew_gr-2024_12_08-19_45_47.jpeg",
    url: "https://www.l-crew.gr/",
    desc: `At WeAreFabbrik, we’re proud to unveil the brand-new digital home for L-Crew, 
        a leader in endurance sports coaching. This collaboration was all about designing a website 
        that reflects their passion for helping athletes achieve their goals—whether it’s conquering a marathon, 
        mastering a triathlon, or excelling in trail running.`,
    _more: [
      '🌐 Modern Design: Sleek, responsive, and optimized for all devices to ensure an engaging user experience.',
      '⚙️ Enhanced Functionality: Seamless navigation and innovative features to meet the needs of both existing and potential customers.',
      '🔍 SEO Optimized: Strategically crafted content and structure to improve search engine rankings and drive organic traffic.'
    ]
  }
];

const Projects = () => {
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  return (
    <div>
      {isMobileDevice ? <MobileNav /> : <TopNav />}
      <div className="case-studies-img">
        <div className="overlay-mask" />
        <Container style={{ maxWidth: '1200px' }}>
          <Typography className="page-header" variant="h1">Our Projects</Typography>
        </Container>
      </div>
      <section className="case-studies-main-section">
        {/* <Typography className="paper-title" variant="h4" component="h4">Trusted by</Typography> */}
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          {projects.map(client => <Project key={client.id} {...client} />)}
        </Stack>
        <div style={{ paddingTop: 20, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            className="projects-button"
            href="https://calendly.com/tsolakidis_k/30min"
            target="_blank"
          >
            Book A Call
          </Button>
        </div>
      </section>
      <Footer />
    </div >
  );
}

export default Projects;
