import React, { Component } from "react";
import { Typography } from "@mui/material";

class Clients extends Component {
  render() {
    const clients = [
      {
        id: 1,
        logo: "/imgs/clients/flashchat_logo.png",
        alt: "Bespoke software tailored to your needs.",
      },
      {
        id: 2,
        logo: "/imgs/clients/IMTF_Logo.jpg",
        alt: "Bespoke software tailored to your needs.",
      },
      {
        id: 3,
        logo: "/imgs/clients/RESOLUTE.jpg",
        alt: "Bespoke software tailored to your needs.",
      },
      {
        id: 4,
        logo: "/imgs/clients/l-crew.png",
        alt: "Bespoke software tailored to your needs.",
      },
      {
        id: 5,
        logo: "/imgs/clients/Dr.-Kelly-Skincare.png",
        alt: "Bespoke software tailored to your needs.",
      },
      {
        id: 5,
        logo: "/imgs/clients/quorin.png",
        alt: "Bespoke software tailored to your needs.",
        cssId: "quorin"
      },
      {
        id: 5,
        logo: "/imgs/clients/finalysis.png",
        alt: "Bespoke software tailored to your needs.",
      }
    ];

    return (
      <section className="clients-container">
        <div id="clients" />
        <Typography className="paper-title" variant="h4" component="h4">Trusted by</Typography>
        {clients.map(client => (
          <img id={client?.cssId} className="client-logo" alt={client.alt} key={client.id} src={client.logo} />
        ))}
      </section>
    );
  }
}
export default Clients;
